import React from 'react'
import classes from './PersonCard.module.css'

import axios from '../../../../utils/axios'
import generateSignature from '../../../../utils/signature'
import { img, uiprofile } from '../../../../utils/endpoints'
import defaultImg from '../../../../utils/unknown_b64.json'

const PersonCard = (props) => {
    var endpoint = img(props.image_id)
    const [b64Content, setB64Content] = React.useState(null)
    React.useEffect(() => {
        setB64Content(defaultImg.b64Content)
        axios.get(endpoint, {
            headers: {
                authorization: generateSignature(process.env.REACT_APP_APPID, process.env.REACT_APP_UPRODIT_ENV, process.env.REACT_APP_APIURL + endpoint)
            }
        })
        .then(response => {
            if(response.data.b64Content) {
                setB64Content(response.data.b64Content)
            }
        })
    }, [])

    return (
        <div className={classes.mainContainer} onClick={() => window.location.href=uiprofile(props.id)}>
            <div className={classes.imageContainer}>
                <div className={classes.backdrop}>
                    <h5 className={classes.specilityTitle}>Specialisé en:</h5>
                    {props.specialities.map((speciality, index) => (
                        <h5 className={classes.specilityText} key={index}>{speciality}</h5>

                    ))}
                </div>
                <img alt="person" className={classes.image} src={"data:image/jpeg;base64," + b64Content} />
            </div>
            <div className={classes.nameContainer}>
                <h5 className={classes.personNameText}>{props.name}</h5>
            </div>
            <div className={classes.rateMainContainer}>
                <div className={classes.rateContainer}>
                    <p className={classes.starCountText}>{props.stars}</p>
                    <i className={`fa-solid fa-star ${classes.starIcon}`}></i>
                </div>
            </div>
        </div>
    )
}

export default PersonCard