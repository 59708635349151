import React from 'react'
import { Container, Row, Col, Spinner } from 'reactstrap'
import classes from './SearchResultsContainer.module.css'
import PersonCard from './PersonCard/PersonCard'
const SearchResultsContainer = (props) => {
    return (
        <Container className={classes.mainContainer}>
            <Row>
                {props.persons ? <React.Fragment>
                    {props.persons.map((person) => (
                        <Col key={person.id} xs='12' md='6' lg='3'>
                            <PersonCard
                                name={person.anonymous_denomination || person.denomination}
                                stars={person.stars_count}
                                specialities={person.specialities}
                                id={person.id}
                                image_id={person.image_id} />
                        </Col>
                    ))}
                </React.Fragment>
                    :
                    <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Spinner color='primary' style={{ height: '50px', width: '50px' }} />
                    </Col>
                }
            </Row>
        </Container>
    )
}

export default SearchResultsContainer