import React from 'react'
import { BrowserRouter,Routes,Route, Navigate } from 'react-router-dom';
import Home from './pages/Home/Home';
const App = ()=> {
  return (
    <BrowserRouter>
    <Routes>
            <Route path="/search" element={<Home />}/>
            <Route path="*" element={<Navigate to="/search" />}/>
    </Routes>
  </BrowserRouter>
  );
}

export default App;
