import React from 'react'
import { Container, Row, Col, Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import SearchHeader from './SearchHeader/SearchHeader'
import classes from './Home.module.css'
import SearchResultsContainer from './SearchResultsContainer/SearchResultsContainer'
import axios from '../../utils/axios'
import generateSignature from '../../utils/signature'
import { search } from '../../utils/endpoints'

const Home = () => {
    const [persons, setPersons] = React.useState(null)
    const [filteredPersons, setFilteredPersons] = React.useState(null)
    const [numberPages, setNumberPages] = React.useState(0)
    const [currentPage, setCurrentPage] = React.useState(0)
    React.useEffect(() => {
        var endpoint = search()
        axios.get(endpoint, {
            headers: {
                authorization: generateSignature(process.env.REACT_APP_APPID, process.env.REACT_APP_UPRODIT_ENV, process.env.REACT_APP_APIURL + endpoint)
            }
        })
        .then(response => {
            setPersons(response.data)
        })                
    }, [])

    React.useEffect(() => {
        if (persons) {
            setNumberPages(Math.ceil(persons.length / 10))
            setFilteredPersons(persons.slice(10 * currentPage, 10 * currentPage + 10))
        }
    }, [persons, currentPage])

    const getNumbersPage = () => {
        const numbers = []
        if (currentPage + 2 <= numberPages)
            for (let i = currentPage; i <= currentPage + 2; i++)
                numbers.push(i)
        else
            for (let i = currentPage; i < numberPages; i++)
                numbers.push(i)
        return numbers
    }
    const searchHandler = (searchedValue) => {
        var endpoint = search(searchedValue)
        axios.get(endpoint, {
            headers: {
                authorization: generateSignature(process.env.REACT_APP_APPID, process.env.REACT_APP_UPRODIT_ENV, process.env.REACT_APP_APIURL + endpoint)
            }
        })
        .then(response => {
            setPersons(response.data)
            setNumberPages(Math.ceil(response.data.length / 10))
            setFilteredPersons(response.data.slice(10 * currentPage, 10 * currentPage + 10))
        }) 
    }

    const clickedPageItem = (index) => {
        setCurrentPage(index)
    }
    return (
        <Container className={classes.mainContainer}>
            <Row>
                <Col>
                    <SearchHeader onSearch={searchHandler} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <SearchResultsContainer persons={filteredPersons} />
                </Col>
            </Row>
            <Row>
                <Col style={{ display: 'flex', justifyContent: 'center' }}>
                    <Pagination aria-label="Page navigation example">
                        {currentPage - 1 >= 0 && <PaginationItem onClick={() => { clickedPageItem(currentPage - 1) }}>
                            <PaginationLink previous href="#" />
                        </PaginationItem>}
                        {getNumbersPage().map((index) => (
                            <PaginationItem
                                key={index}
                                active={currentPage === index}
                                onClick={() => { clickedPageItem(index) }} >
                                <PaginationLink href="#">
                                    {index}
                                </PaginationLink>
                            </PaginationItem>
                        ))}
                        {numberPages > currentPage + 1 && <PaginationItem onClick={() => { clickedPageItem(currentPage + 1) }}>
                            <PaginationLink next href="#" />
                        </PaginationItem>}
                    </Pagination>
                </Col>
            </Row>
        </Container>
    )
}

export default Home