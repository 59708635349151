import React from 'react'
import { Container, Input, Row, Col, Button } from 'reactstrap'
import classes from './SearchHeader.module.css'

const SearchHeader = (props) => {
    const [searchedValue, setSearchedValue] = React.useState('')

    const checkClickedEnter = (e) => {
        if (e.key === "Enter") {
            props.onSearch(searchedValue)
        }
    }
    return (
        <Container>
            <Row>
                <Col>
                    <div className={classes.searchContainer}>
                        <Input value={searchedValue}
                            onChange={(e) => setSearchedValue(e.target.value)}
                            onKeyPress={(e) => checkClickedEnter(e)}
                        />
                        <Button className={classes.searchButton} onClick={() => { props.onSearch(searchedValue) }}>
                            <i className={`fa-solid fa-magnifying-glass ${classes.searchIcon}`}></i>
                        </Button >
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default SearchHeader