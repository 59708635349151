export const search = (term) => {
   var root_endpoint = "/v1/search/all?usecase=perso&startIndex=0&maxResults=10"
   if (term) {
      return `${root_endpoint}&terms=${term}`
   }
   return root_endpoint
}

export const img = (id) => {
    return `/v2/profile/picture/f/${id}`
}

export const uiprofile = (id) => {
   return `${process.env.REACT_APP_UPRODIT_URL}/profile/personal/${id}`
}
